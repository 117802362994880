<template>
    <Button type="button" v-tooltip.top="'Create VA'" icon="pi pi-credit-card" label="" class="p-button-ghost ml-2" @click="(display = true), cekCreated()" />
    <div class="col-12 lg:col-12">
        <Dialog header="Create VA Beasiswa" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()">
            <div class="grid">
                <div class="col cara-box">
                    <div class="grid">
                        <div class="col">
                            <div class="bank">
                                <div>Total Tagihan</div>
                                <div>Rp. {{ convert(data.tagihan) }}</div>
                            </div>

                            <div class="bank">
                                <div>BANK</div>
                                <div><img src="@/assets/BTNLOGO.png" alt="" /></div>
                            </div>
                            <div class="bank">
                                <div>Nama</div>
                                <div>{{ data.nama_mahasiswa_dts }}</div>
                            </div>
                            <div class="bank" v-if="cek.createdate != '000000'">
                                <div>Nomor VA</div>
                                <div>{{ Va }}</div>
                            </div>
                            <div class="bank" v-if="cek.expired != '' && cek">
                                <div>Kadaluarsa VA</div>
                                <div v-if="!today">{{ kadaluarsaVa }}</div>
                                <div v-else>Expired</div>
                            </div>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <div class="mb-4 mt-4" v-if="iscek">
                                <Button class="p-button-success CreateVa" @click="createVA()" label="Create Virtual Account" :disabled="!showButtoncreateVA"> </Button>
                            </div>
                            <!-- <div class="mb-4 mt-4">
                            <center>
                                <Button class="p-button-success CreateVa" @click="printBTN(), showButtonprintBTN = false"
                                    :disabled="!showButtonprintBTN" v-if="cek.createdate != '000000' && !today"> Simpan
                                    Tagihan </Button>
                            </center>
                        </div> -->
                        </div>
                        <div class="col-4"></div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <div @click="cara = !cara" style="margin-bottom: 20px" v-if="cek.createdate != '000000'">
                                <font-awesome-icon icon="fa-regular fa-circle-question" />
                                <span> Cara Bayar </span>
                                <font-awesome-icon v-if="cara" icon="fa-regular fa-square-caret-up" /><font-awesome-icon v-else icon="fa-regular fa-square-caret-down" />
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <div v-if="cek.createdate != '000000'">
                                <div @click="steps('ATM')" class="card-cara">
                                    <div class="card-cara-button">
                                        <span>ATM</span>
                                        <font-awesome-icon v-if="step == 'ATM'" icon="fa-regular fa-square-caret-up" /><font-awesome-icon v-else icon="fa-regular fa-square-caret-down" />
                                    </div>
                                    <div v-if="step == 'ATM'" class="text-cara mt-4">
                                        <div class="text-cara-line">
                                            <p>1.</p>
                                            <p>Masukkan Kartu Anda.</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>2.</p>
                                            <p>Pilih Bahasa.</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>3.</p>
                                            <p>Masukkan PIN ATM Anda.</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>4.</p>
                                            <p>Kemudian, pilih Menu Lainnya.</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>5.</p>
                                            <p>Pilih "Pembayaran"</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>6.</p>
                                            <p>Pilih “Multipayment”</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>7.</p>
                                            <p>Pilih “Virtual Account”</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>8.</p>
                                            <p>
                                                Masukkan nomor Virtual Account Anda
                                                <span style="color: green">{{ Va }}</span>
                                            </p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>9.</p>
                                            <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>10.</p>
                                            <p>Konfirmasi, apabila telah sesuai, lanjutkan transaksi</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>11.</p>
                                            <p>Transaksi Anda telah selesai</p>
                                        </div>
                                    </div>
                                </div>

                                <div @click="steps('IB')" class="card-cara">
                                    <div class="card-cara-button">
                                        <span>Mobile Banking BTN</span>
                                        <font-awesome-icon v-if="step == 'IB'" icon="fa-regular fa-square-caret-up" /><font-awesome-icon v-else icon="fa-regular fa-square-caret-down" />
                                    </div>
                                    <div v-if="step == 'IB'" class="mt-4">
                                        <div class="text-cara-line">
                                            <p>1.</p>
                                            <p>Akses BTN Mobile Banking dari handphone kemudian masukkan user ID dan password</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>2.</p>
                                            <p>Pilih menu “Pembayaran”</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>3.</p>
                                            <p>Pilih menu “Virtual Account” kemudian pilih rekening debet</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>4.</p>
                                            <p>
                                                Masukkan nomor Virtual Account Anda
                                                <span style="color: green">{{ Va }}</span>
                                            </p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>5.</p>
                                            <p>Tekan “Kirim”</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>6.</p>
                                            <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>7.</p>
                                            <p>Konfirmasi transaksi dan masukkan PIN dan Kode OTP (dikirim melalui SMS) Transaksi</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>8.</p>
                                            <p>Tekan “Kirim”</p>
                                        </div>
                                        <div class="text-cara-line">
                                            <p>9.</p>
                                            <p>Pembayaran Anda Telah Berhasil</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
export default {
    props: ['datas'],
    emits: {
        alert: null,
    },
    data() {
        return {
            cara: false,
            metode: 0,
            step: '',
            cek: '',
            date: '',
            interval: '',
            expired: '',
            showButtoncreateVA: true,
            showButtonprintBTN: true,
            display: false,
        };
    },
    computed: {
        Va() {
            let vm = this;
            let x = vm.data.NIM_DTS;
            return vm.$store.state.billing.btn_prefix_bendahara + x.substring(x.length - 8);
        },
        iscek() {
            return this.cek != '';
        },
        data() {
            return this.datas;
        },
        kadaluarsaVa() {
            let vm = this;
            if (vm.date) {
                let d = vm.date.days();
                let h = vm.date.hours();
                let m = vm.date.minutes();
                let s = vm.date.seconds();
                if (Number(h) < 10) {
                    h = '0' + h;
                }
                if (Number(m) < 10) {
                    m = '0' + m;
                }
                if (Number(s) < 10) {
                    s = '0' + s;
                }
                return `${d} hari ${h} : ${m} : ${s}`;
            } else {
                return 'mohon tunggu';
            }
        },
        today() {
            let vm = this;
            let x = null;
            if (vm.expired) {
                x = vm.$moment(vm.expired) < vm.$moment();
            }
            return x;
        },
    },
    mounted() {
        // this.cekCreated();
    },
    methods: {
        steps(x) {
            if (this.step == x) {
                this.step = '';
            } else {
                this.step = x;
            }
        },
        async cekCreated() {
            let vm = this;
            // vm.$store.dispatch('set_loading', true);
            let cek = await vm.$axiosbilling.post('btn/detailsById', {
                ref: vm.data.trx_id,
                va: vm.Va,
            });
            console.log(cek, 'cek');
            vm.cek = await cek.data.data[0];
            vm.expired = vm.$moment(vm.cek.expired, 'YYMMDDHHmm');
            vm.setTimer();
            // vm.$store.dispatch('set_loading', false);
            // vm.cek = await cek.data.data[0];
            // vm.expired = vm.$moment(vm.cek.expired, 'YYMMDDHHmm');
            // vm.setTimer();
        },
        setTimer() {
            let vm = this;
            let interval = setInterval(() => {
                let now = vm.$moment();
                vm.date = vm.$moment.duration(vm.expired.diff(now));
            }, 1000);
            vm.interval = interval;
            clearInterval(interval - 1);
        },
        async createVA() {
            let vm = this;
            vm.showButtoncreateVA = false;
            // vm.$store.dispatch('set_loading', true);
            let create = await vm.$axiosbilling.post('btn/registerBendahara', {
                nim: vm.data.NIM_DTS,
            });
            // console.log(create, 'bikin');
            if (create.data.status == 200) {
                if (create.data.message == 'sukses') {
                    vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate VA Beasiswa', life: 3000 });
                    vm.cekCreated();
                    vm.showButtoncreateVA = true;
                } else {
                    vm.$toast.add({ severity: 'success', summary: 'Warning', detail: create.data.message, life: 3000 });
                    vm.showButtoncreateVA = true;
                }
            } else {
                vm.$toast.add({ severity: 'success', summary: 'ERROR', detail: create.data.messare, life: 3000 });
                vm.showButtoncreateVA = true;
                // console.log(create.data.message, 'error CR8VA');
            }

            this.cekCreated();
        },
        convert(x) {
            if (x) {
                return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            }
        },
        // printBTN() {
        //     let vm = this;
        //     window.open(vm.ip2 + '/detailsTagihanStudi/downloadTagihanBTN/' + vm.$store.state.billing.simadu_role.NIM_DTS + '/mahasiswa', '_blank');
        //     vm.showButtonprintBTN = true
        // },
    },
};
</script>

<style scoped>
.container-fluid {
    overflow-y: auto;
}

.cara-box {
    min-height: 80%;
    width: 350px;
    text-align: start;
    border-radius: 1rem;
    border: 1px solid transparent;
    /* border-top-color: rgba(225, 225, 225, 0.5);
  border-left-color: rgba(225, 225, 225, 0.5);
  border-bottom-color: rgba(225, 225, 225, 0.1);
  border-right-color: rgba(225, 225, 225, 0.1); */
    /* background-color: rgba(225, 225, 225, 0.1); */
    /* box-shadow: 1rem 1rem 1rem 1rem rgba(0, 0, 0, 0.1); */
    background-color: rgba(225, 225, 225, 0.5);
    padding: 5px;
}

.card-cara {
    /* background-color: aqua; */
    min-height: 50px;
    margin-bottom: 10px;
    border-radius: 1rem;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(225, 225, 225, 0.1);
    box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.1);
    padding: 1em;
    overflow-y: auto;
}

.card-cara-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-cara {
    font-size: 16px;
    line-height: 17px;
}

.text-cara-line {
    display: flex;
    grid-gap: 15px;
}

.bank {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    margin: 10px 0 10px 0;
}

img {
    width: 100%;
    height: 20px;
}

.CreateVa:hover {
    color: #ffffff;
    background-color: #027a48;
    transform: scale(1.09);
}
</style>
